<template>
  <div>
    <div class="details">
      <p class="details_title">修改密码</p>
      <el-divider></el-divider>
      <el-form
        style="width: 500px"
        status-icon
        :rules="rules"
        ref="newlPasswords"
        label-position="top"
        label-width="80px"
        :model="newlPassword"
      >
        <el-form-item label="原密码" prop="oldPassword">
          <el-input
            v-model="newlPassword.oldPassword"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            v-model="newlPassword.newPassword"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="notarizePassword">
          <el-input
            v-model="newlPassword.notarizePassword"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <el-button class="button_cancel input" @click="cancel()">取消</el-button>
    <el-button class="button_preserve" @click="amendPasswords">保存</el-button>
  </div>
</template>

<script>
import { amendPassword } from "@/api/systemAdmin/PIM.js";
export default {
  name: "alterPassword",
  data() {
    return {
      newlPassword: {
        newPassword: "",
        oldPassword: "",
        notarizePassword: ""
      },
      rules: {
        newPassword: [
          { required: true, message: "请输入密码", trigger: "blur" }
        ],
        notarizePassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (value === this.newlPassword.newPassword) {
                callback();
              } else {
                callback(new Error("两次密码不一致"));
              }
            },
            trigger: "change"
          }
        ],
        oldPassword: [
          { required: true, message: "请输入密码", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    //取消
    cancel() {
      this.$router.go(-1);
    },
    //保存
    amendPasswords() {
      this.$refs["newlPasswords"].validate(valid => {
        if (valid) {
          const config = {
            userId: localStorage.getItem("userId"),
            oldPassword: this.newlPassword.oldPassword,
            newPassword: this.newlPassword.newPassword,
            isApp: 0
          };
          amendPassword(config).then(({ data }) => {
            if (data.resultCode === 0) {
              this.$message({
                showClose: true,
                message: "重置成功",
                type: "success"
              });
              this.$router.push({ path: "/login" });
            } else {
              this.$message({
                showClose: true,
                message: data.errorDesc || "重置失败",
                type: "error"
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      //   if (
      //     this.newlPassword.newPassword !== "" &&
      //     this.newlPassword.oldPassword !== "" &&
      //     this.newlPassword.notarizePassword !== "" &&
      //     this.newlPassword.newPassword == this.newlPassword.notarizePassword
      //   ) {
      //     amendPassword(data).then(res => {
      //       if (res.data.desc === "SUCCESS") {
      //         this.$router.go(-1);
      //       } else {
      //         this.$message({
      //           showClose: true,
      //           message: res.data.errorDesc,
      //           type: "error"
      //         });
      //       }
      //       console.log(res);
      //     });
      //   }
    }
  }
};
</script>

<style scoped>
.input {
  margin-top: 20px;
}
.details {
  background: #ffffff;
  border: 1px solid #e8eaf0;
  border-radius: 3px;
  border-radius: 3px;
  padding: 30px;
  margin-top: 30px;
}
.details_title {
  font-family: PingFangSC-Medium;
  font-size: 16px;
  color: #333333;
  font-weight: bold;
  letter-spacing: -0.05px;
}
/deep/ .el-divider .el-divider--horizontal {
  border: 2px solid #ebebeb;
  font-weight: bold;
}
</style>
